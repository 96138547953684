import React from "react"
import "./Style/Header.css"

export const Header = () => {
  return (
    <div className="head">
      <h1>Livrarium</h1>
      <p>API de recherche Google</p>
    </div>
  )
}
